<template>
    <div class="page-tabs mb-4">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <router-link
                    :to="{name: 'resultados'}"
                    class="nav-link chart-tab"
                    :class="tab === 'prova' && 'active'"
                    aria-selected="false"
                >
                    Provas
                </router-link>
            </li>
            <li class="nav-item" role="presentation">
                <router-link
                    :to="{name: 'resultados-turma'}"
                    class="nav-link chart-tab"
                    :class="tab === 'turma' && 'active'"
                    aria-selected="false"
                >
                    Turmas
                </router-link>
            </li>
            <li class="nav-item" role="presentation">
                <router-link
                    :to="{name: 'resultados-aluno-lista'}"
                    class="nav-link chart-tab"
                    :class="tab === 'aluno' && 'active'"
                    aria-selected="false"
                >
                    Alunos
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
  export default{
    props: ["tab"]
  }

</script>